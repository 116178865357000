/* eslint-disable no-empty */
import config from '@/config';
import { HttpService } from '@/services';
import { appStore } from '@/store';
import {
  FileResponse,
  ImagePayload,
  ImageResponse,
  OldMessagePayload,
  OldMessageResponse,
  QRPayload,
  QRResponse,
  TextPayload,
  TextResponse,
  VideoRoom,
} from './@types';

class ChatAPI {
  constructor(private http: HttpService) {}

  sendMessage(payload: TextPayload | ImagePayload) {
    return this.http.post<typeof payload extends TextPayload ? TextResponse : ImageResponse>(
      'messages?action=message_post',
      payload,
    );
  }

  sendWithQR(payload: QRPayload) {
    return this.http.post<QRResponse>('messages', payload);
  }

  message(payload: QRPayload) {
    return this.http.post<TextResponse>('messages', payload);
  }

  uploadFile(payload: File) {
    const file = new FormData();
    file.append('file', payload);
    return this.http.upload<FileResponse>(`/api/v1/tools/uploader`, file);
  }

  getOldMessages({ from_message_id, limit = 200 }: OldMessagePayload) {
    return this.http.post<OldMessageResponse>('messages?action=message_history', {
      history: {
        from_message_id,
        limit,
      },
    });
  }

  getVideoRoom() {
    return this.http.post<VideoRoom>('session-room', {});
  }
}

const http = new HttpService(config.apiURL, {
  getToken: () => appStore.getState().token || '',
});
export const chatAPI = new ChatAPI(http);
